<template>
  <a-cascader
    v-model="selectedMenu"
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    :options="list"
    :show-search="{ filter }"
    :placeholder="placeholder"
    :change-on-select="selectType"
    @change="onChange"
  />
</template>
  <script>
export default {
  props: {
    // 数据源
    list: {
      type: Array,
    },
    // 提示
    placeholder: {
      type: String,
      default: "请选择",
    },
    // 是否选择父级
    selectType: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      obj: [],
      selectedMenu: [],
    };
  },
  methods: {
    onChange(value, selectedOptions) {
      this.obj = selectedOptions[selectedOptions.length - 1];
    },
    // 获取值
    getSelectValue() {
      return this.obj;
    },
    // 筛选
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    // 清空
    resetForm() {
        this.selectedMenu = [], 
        this.obj = {};
    },
    // 回显,传入value以及list树状数据
    showLabel({ value, list }) {
      (this.selectedMenu = this.findParentValues(list, value) || []),
        (this.obj = this.findInCommunities(list, value));
    },
    // 通过value找父级对象
    findInCommunities(communities, value) {
      for (let community of communities) {
        if (community.value === value) {
          return community;
        }
        let found = this.findInCommunities(community.children, value);
        if (found) {
          return found;
        }
      }
      return null;
    },
    // 通过value找父级value
    findParentValues(children, targetValue, parentValues = []) {
      for (let child of children) {
        if (child.value === targetValue) {
          // 如果当前节点是我们要找的，返回所有父级value（包括自身）
          return [...parentValues, child.value];
        }
        if (child.children && child.children.length > 0) {
          // 递归查找子节点
          const result = this.findParentValues(child.children, targetValue, [
            ...parentValues,
            child.value,
          ]);
          if (result) {
            return result;
          }
        }
      }
      // 如果没有找到，返回null或空数组
      return null;
    },
  },
};
</script>
  
